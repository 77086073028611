<template>
	<div>
		<com-mon>
			<el-card class="scene-access">
				<div class="title">场景接入</div>
				<div class="tip">微信客服可以在微信内、外各个场景中接入</div>

				<div class="list">
					<div class="item" v-for="(item, index) in list" :key="index">
						<div class="item-title">{{item.title}}</div>
						<div class="item-desc">
							<span style="padding-right: 20px;">{{item.desc}}</span>
							<el-link type="primary" :href="item.link" target="_blank">查看接入说明
							</el-link>
						</div>
						<div class="opened-account" v-if="item.selectData && item.selectData.Name">
							已开启接入，客服帐号:
							<div class="select-data" style="margin-left: 5px;">
								<img :src="imgUrl + item.selectData.HeadUrl">
								<div class="name" style="color: #606266;">{{item.selectData.Name}}</div>
							</div>
							<el-link type="primary" style="margin-left: 30px;" @click="handleConect(item)">修改客服账号
							</el-link>
							<el-link type="danger" style="margin-left: 10px;" @click="handleClose(item)">关闭接入</el-link>
						</div>
						<div class="btn">
							<el-popover v-if="item.type !== 5" placement="bottom" width="200" trigger="click">
								<div class="experience-code">
									<div class="experience-code-title">微信扫码体验</div>
									<img :src="item.qrCode" />
								</div>
								<el-button size="mini" type="primary" slot="reference">微信扫码体验</el-button>
							</el-popover>
							<div class="start-conect-btn" v-if="item.showConectBtn && !item.selectData.Id"
								@click="handleConect(item)">开启接入</div>
						</div>
						<div class="example-title">客户咨询示例</div>
						<div class="example-list">
							<div class="example-item" v-for="(obj, i) in item.exapmle" :key="i">
								<img :src="obj.imageUrl" />
								<div class="txt">{{obj.text}}</div>
							</div>
						</div>
					</div>
				</div>

				<el-dialog :visible.sync="visible" :title="dialogTitle" width="600px">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="客服账号:" prop="selectData">
							<el-popover v-model="popVisible" placement="bottom-start" width="400" trigger="click">
								<!-- 改动 -->
								<div class="kf-list">
									<template v-if="kfList.length">
                                       <div class="kf-item" 
									    v-for="(item, index) in kfList"
										:key="index" @click="handleSelect(item)">
										<img :src="imgUrl + item.HeadUrl">
										<div class="name">{{item.Name}}</div>
									</div>
									</template>
									<!-- <div class="kf-item" v-if="kfList.length" 
									    v-for="(item, index) in kfList"
										:key="index" @click="handleSelect(item)">
										<img :src="item.HeadUrl">
										<div class="name">{{item.Name}}</div>
									</div> -->
									<div v-if="!kfList.length" class="kf-item-nodata">
										还未添加客服帐号，<el-link type="primary" @click="handleToAdd">前往添加</el-link>
									</div>
								</div>
								<div class="mock-input" slot="reference">
									<div class="text">
										<div class="placeholer" v-if="!ruleForm.Id">请选择接入客服帐号</div>
										<div class="select-data" v-else>
											<img :src="imgUrl + ruleForm.selectData.HeadUrl">
											<div class="name">{{ruleForm.selectData.Name}}</div>
										</div>
									</div>
									<div class="icon"><i class="el-icon-arrow-down"></i></div>
								</div>
							</el-popover>
						</el-form-item>
					</el-form>

					<div slot="footer" class="dialog-footer">
						<el-button @click="visible = false">取 消</el-button>
						<el-button type="primary" @click="handleSureOpen">确定开启</el-button>
					</div>

				</el-dialog>

			</el-card>
		</com-mon>
	</div>
</template>

<script>
	import Commom from "@/views/customerServe/component/Common.vue" //引入组件
	
	import config from '@/config/index';
	
	import {
		qyWeixinKfScenesConfigList,
		qyWeixinKfScenesConfigSave,
		qyWeixinKfAccountList
	} from '@/api/sv1.0.0.js'
	export default {
		components: {
			"com-mon": Commom
		},
		data() {

			var checkSelectData = (rule, value, callback) => {
				if (!this.ruleForm.selectData) {
					callback(new Error('请选择客服账号'));
				} else {
					callback();
				}
			};

			return {
				imgUrl: config.IMG_BASE,
				list: [{
						type: 1,
						title: '1.在公众号菜单接入',
						desc: '开启在公众号菜单接入，客户点击公众号菜单联系客服，会话可接入微信客服；若关闭该场景，会话接入网页客服',
						link: 'https://jusnn6k8al.feishu.cn/docs/doccnvSjjvEaFVATvQEeYNkb6Me',
						qrCode: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-tiyan.png',
						showConectBtn: true,
						exapmle: [{
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-1.png',
							text: '公众号中点击菜单'
						}],
						selectData: {}
					},
					{
						type: 2,
						title: '2.在小程序商城内接入',
						desc: '开启在小程序商城内接入，客户进入小程序商城，商品详情页、订单详情页、商城首页等，点击联系客服，会话可接入微信客服；若关闭该场景，会话接入网页客服',
						link: 'https://jusnn6k8al.feishu.cn/docs/doccn7qrsMNlkryMcjCW2BcCNee',
						qrCode: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-tiyan.png',
						showConectBtn: true,
						exapmle: [{
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-2.png',
							text: '小程序商城中点击'
						}],
						selectData: {}
					},
					{
						type: 3,
						title: '3.在视频号中接入',
						desc: '在你的企业官方视频号中接入微信客服。接入后，用户可以在视频号主页处点击「联系客服」。',
						link: 'https://jusnn6k8al.feishu.cn/docs/doccn8dNYgDP17OHoBtyp0MlMJg',
						qrCode: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-tiyan-1.png',
						exapmle: [{
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-3.png',
							text: '在视频号中咨询客服'
						}],
						selectData: {}
					},
					{
						type: 4,
						title: '4.在微信内其他场景接入',
						desc: '在微信内的其他场景中接入微信客服，如微信内网页、搜一搜品牌官方区、支付凭证等。接入后，用户可在以上入口咨询客服。',
						link: 'https://jusnn6k8al.feishu.cn/docs/doccngzTKUVPNn80uw3XGrwJsHh',
						qrCode: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-tiyan.png',
						exapmle: [{
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-4-1.png',
							text: '微信网页中点击按钮'
						}, {
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-4-2.png',
							text: '搜一搜品牌官方区发起咨询'
						}, {
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-4-3.png',
							text: '微信支付凭证点击联系商家'
						}],
						selectData: {}
					},
					{
						type: 5,
						title: '5.在微信外场景接入',
						desc: '在微信外部网页接入微信客服，接入后，用户可在对应入口咨询客服。',
						link: 'https://jusnn6k8al.feishu.cn/docs/doccnWko9JbXNPDDkkh2D2RfXZf',
						qrCode: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-tiyan.png',
						exapmle: [{
							imageUrl: 'https://cdn.dkycn.cn/images/melyshop/scene-access-example-5-2.png',
							text: 'Web网页点击按钮'
						}],
						selectData: {}
					}
				],

				visible: false,
				type: 1,
				dialogTitle: '',

				popVisible: false,
				kfList: [],

				ruleForm: {
					Id: null,
					selectData: null
				},
				rules: {
					selectData: [{
						required: true,
						validator: checkSelectData,
						trigger: 'change'
					}]
				}
			}
		},
		created() {
			this.init();
			this.getKFList();
		},
		methods: {
			async init() {
				try {
					const res = await qyWeixinKfScenesConfigList();

					this.list.map(item => {
						var dt = {};
						res.Result.map(obj => {
							if (item.type == obj.QyWeixinKfScenesConfigType && obj.IsOpen) {
								dt = obj;
								dt.Id = obj.QyWeixinKfAccountId;
							}
						})
						item.selectData = dt;
						return item;
					})
					
				} catch (e) {}
			},
			async getKFList() {
				try {
					const res = await qyWeixinKfAccountList();
					this.kfList = res.Result;
				} catch (e) {}
			},
			handleConect(record) {
				this.visible = true;
				this.type = record.type;

				if (record.selectData.Id) {
					this.ruleForm.Id = record.selectData.Id;
					this.ruleForm.selectData = record.selectData;
				} else {
					this.ruleForm.Id = 0;
					this.ruleForm.selectData = null;
				}

				if (this.type == 1) {
					this.dialogTitle = '在公众号菜单接入';
				}
				if (this.type == 2) {
					this.dialogTitle = '在小程序商城内接入';
				}
				
				this.$nextTick(()=>{
					this.$refs['ruleForm'].clearValidate();
				})
				
			},
			handleClose(record) {
				this.type = record.type;
				this.ruleForm.selectData = record.selectData;
				this.sureSave(false)
			},
			handleSelect(record) {
				this.ruleForm.Id = record.Id;
				this.ruleForm.selectData = record;
				this.popVisible = false;
				this.$refs['ruleForm'].clearValidate();
			},
			handleSureOpen() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.sureSave(true);
					} else {}
				});
			},
			async sureSave(bol) {
				try {
					const res = await qyWeixinKfScenesConfigSave({
						QyWeixinKfScenesConfigType: this.type,
						QyWeixinKfAccountId: this.ruleForm.selectData.Id,
						IsOpen: bol
					})

					if (res.IsSuccess) {
						this.$message.success('操作成功');
						this.init();
						this.visible = false;
					}

				} catch (e) {
					//TODO handle the exception
				}
			},
			handleToAdd() {
				window.open(location.origin + '/index.html#/customerServe/WxCustomerServe/serviceaccount')
			}
		}
	}
</script>



<style lang="scss" scoped>
	.experience-code {
		padding: 10px 0;


		.experience-code-title {
			font-size: 14px;
			color: #606266;
			text-align: center;
		}

		img {
			display: block;
			width: 150px;
			height: 150px;
			margin: 0 auto;
		}
	}


	.kf-list {
		padding-top: 10px;
		max-height: 300px;
		overflow: auto;

		.kf-item {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			cursor: pointer;

			img {
				flex: 0 0 auto;
				width: 40px;
				height: 40px;
				border-radius: 2px;
				margin-right: 10px;
			}

			.name {
				flex: 1 1 auto;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.kf-item-nodata {
			padding: 30px 0 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
		}
	}

	.mock-input {
		width: 400px;
		height: 36px;
		display: flex;
		cursor: pointer;
		border-radius: 2px;
		border: 1px solid #DCDFE6;

		.text {
			flex: 1 1 auto;
			font-size: 14px;
			padding-left: 10px;
			overflow: hidden;

			.placeholer {
				color: #ccc;
			}


		}

		.icon {
			flex: 0 0 auto;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ccc;
		}
	}

	.select-data {
		display: flex;
		align-items: center;

		img {
			flex: 0 0 auto;
			width: 20px;
			height: 20px;
			border-radius: 2px 2px 2px 2px;
			margin-right: 10px;
		}

		.name {
			flex: 1 1 auto;
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.title {
		font-size: 18px;
		padding: 0 0 10px;
	}

	.tip {
		font-size: 14px;
		color: #999;
		padding-bottom: 20px;
	}

	.list {
		.item {
			border-top: 1px solid #DCDFE6;
			padding: 30px 0;

			.item-title {
				font-size: 16px;
				margin-bottom: 10px;
			}

			.item-desc {
				font-size: 14px;
				color: #999;
				line-height: 22px;
				margin-bottom: 20px;

				display: flex;
				flex-wrap: wrap;
			}

			.opened-account {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-size: 14px;
				margin-bottom: 16px;
				color: #606266;
			}

			.btn {
				padding: 0 0 20px;

				display: flex;


				.start-conect-btn {
					width: 88px;
					height: 28px;
					line-height: 28px;
					background: #F2F2F2;
					border-radius: 2px 2px 2px 2px;
					font-size: 12px;
					color: #409EEF;
					text-align: center;
					margin-left: 20px;
					cursor: pointer;
				}
			}

			.example-title {
				font-size: 12px;
				color: #999;
				margin-bottom: 3px;
			}

			.example-list {
				display: flex;

				.example-item {
					width: 155px;
					margin-right: 30px;

					img {
						width: 155px;
						height: 186px;
						display: block;
					}

					.txt {
						// text-align: center;
						font-size: 12px;
						color: #999;
						margin-top: 8px;
					}
				}
			}
		}
	}
</style>
